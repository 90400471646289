import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { pick } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';

enum Columns {
  SkuChart = 'sku_chart',
  Size = 'size',
  SkuName = 'sku_name',
  SkuExternalId = 'sku_external_id',
  Replenishment = 'replenishment',
  Sold = 'sold',
  SalesRate = 'salesRate',
  Stock = 'stock',
  OptimalStock = 'optimalStock',
  WarehouseInventory = 'warehouseInventory',
  WhNames = 'warehouseName',
  NextReplenishment = 'replenishmentTime',
  Coverage = 'coverage',
  ExpectedCoverage = 'expectedCoverage',
  Constraints = 'constraints',
}

const DATABASE_KEY = 'replenishment-location-details-page';

export const useReplenishmentLocationDetailsPageStore = defineStore(
  StoreNames.ReplenishmentLocationDetailsPage,
  () => {
    const columnsVisibility = ref<Record<string, boolean>>({
      [Columns.SkuChart]: true,
      [Columns.Size]: true,
      [Columns.SkuName]: false,
      [Columns.SkuExternalId]: false,
      [Columns.Replenishment]: true,
      [Columns.Sold]: true,
      [Columns.SalesRate]: false,
      [Columns.Stock]: true,
      [Columns.OptimalStock]: true,
      [Columns.WarehouseInventory]: false,
      [Columns.WhNames]: false,
      [Columns.NextReplenishment]: false,
      [Columns.Coverage]: false,
      [Columns.ExpectedCoverage]: false,
      [Columns.Constraints]: true,
    });

    const columnsOrder = ref<string[]>([
      Columns.SkuChart,
      Columns.Size,
      Columns.SkuName,
      Columns.SkuExternalId,
      Columns.Replenishment,
      Columns.Sold,
      Columns.SalesRate,
      Columns.Stock,
      Columns.OptimalStock,
      Columns.WarehouseInventory,
      Columns.WhNames,
      Columns.NextReplenishment,
      Columns.Coverage,
      Columns.ExpectedCoverage,
      Columns.Constraints,
    ]);

    const width = ref(680);

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    async function fetch(force?: boolean) {
      if (fetching.value || (fetched.value && !force)) {
        return;
      }

      fetching.value = true;

      // Fetch persisted state from the server

      const { data } = await api.getUiStates({ key: DATABASE_KEY });

      const persistedState = data.data[0];

      if (persistedState) {
        // TODO: validate and merge data, persist again in case of invalid values
        columnsVisibility.value = {
          ...columnsVisibility.value,
          ...pick(persistedState.value.columnsVisibility, Object.keys(columnsVisibility.value)),
        };

        columnsOrder.value = persistedState.value.columnsOrder;

        width.value = persistedState.value.width;
      }

      fetching.value = false;
      fetched.value = true;
    }

    async function persist() {
      await api.saveUiState({
        key: DATABASE_KEY,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          width: width.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    watch(
      [columnsVisibility, columnsOrder, width],
      () => {
        if (autoPersistEnabled.value) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    watch(fetched, () => {
      autoPersistEnabled.value = true;
    });

    // start migration from local storage
    // TODO: remove in September 2024
    const localStorageKey = 'onebeat-app:replenishment-location-details-page';
    const localStorageState = window.localStorage.getItem(localStorageKey);

    if (localStorageState) {
      try {
        const values = JSON.parse(localStorageState);

        columnsVisibility.value = values.columnsVisibility;
        columnsOrder.value = values.columnsOrder;
        width.value = values.width;
        persist();
        fetched.value = true;
      } catch (error) {
        // do nothing
      } finally {
        window.localStorage.removeItem(localStorageKey);
      }
    }
    // end migration from local storage

    return {
      fetching,
      fetched,
      fetch,
      columnsVisibility,
      columnsOrder,
      width,
    };
  },
);
