{
  "analytics": {
    "messages": {
      "noReportsFound": "No reports found"
    },
    "title": "Analytics"
  },
  "app": {
    "actions": {
      "postpone": "Postpone",
      "update": "Update"
    },
    "messages": {
      "newAppVersionIsAvailable": "New app version is available"
    }
  },
  "charts": {
    "axisLabels": {
      "days": "Days",
      "units": "Units"
    }
  },
  "common": {
    "actions": {
      "analyzeData": "Analyze data",
      "apply": "Apply",
      "applyAsDefault": "Apply as default",
      "approve": "Approve",
      "approveAll": "Approve all",
      "avoid": "Avoid",
      "cancel": "Cancel",
      "clear": "Clear",
      "clearFilters": "Clear filters",
      "contactSupport": "Contact support",
      "copyLink": "Copy link",
      "delete": "Delete",
      "download": "Download",
      "downloadChartImage": "Download chart image",
      "downloadPickingList": "Download picking list",
      "edit": "Edit",
      "goToHomePage": "Go to home page",
      "no": "No",
      "openColumnsVisibilitySettings": "Columns",
      "openFilters": "Filter",
      "openMetricsVisibilitySettings": "Metrics",
      "openSortSettings": "Sort",
      "recalculate": "Recalculate",
      "reject": "Reject",
      "reload": "Reload",
      "save": "Save",
      "seeSkuChart": "See SKU history chart",
      "undo": "Undo",
      "viewDetails": "See details",
      "viewLess": "View less",
      "viewMore": "View more",
      "yes": "Yes"
    },
    "errors": {
      "badRequest": "Bad request",
      "loadDataFailed": "Data failed to load",
      "notFound": "Not found"
    },
    "messages": {
      "areYouSureYouWantToDeleteTheseItems": "Are you sure you want to delete this @:common.plurals.items{''}? | Are you sure you want to delete these @:common.plurals.items{''}?",
      "columnsSearchHint": "Search columns",
      "columnsVisibilitySelectionHint": "Select columns to display",
      "dashboardLinkCopiedToClipboard": "Dashboard link copied to clipboard",
      "dashboardTemporarilyUnavailable": "Dashboard temporarily unavailable. Please try again later.",
      "deleteItems": "Delete items",
      "deletingItems": "Deleting items",
      "failedToCopyLinkToClipboard": "Copying link to clipboard failed",
      "failedToDeleteItems": "Failed to delete items",
      "filteringUnavailableDueToDataIndexing": "Data indexing is in progress. Filtering is temporary unavailable.",
      "linkCopiedToClipboard": "Link copied to clipboard",
      "metricsSearchHint": "Search metrics",
      "metricsVisibilitySelectionHint": "Select metrics to display",
      "noOptionsFound": "No options found",
      "pageNotFound": "Page not found",
      "searchHint": "Search",
      "searchingUnavailableDueToDataIndexing": "Data indexing is in progress. Searching is temporary unavailable.",
      "skuChartLinkCopiedToClipboard": "SKU chart link copied to clipboard",
      "typeToSearchOptions": "Type to search options"
    },
    "plurals": {
      "days": "day | days",
      "events": "event | events",
      "items": "item | items",
      "months": "month | months"
    },
    "statuses": {
      "lastUpdateTimeAgo": "Updated {timeAgo}",
      "loading": "Loading",
      "noColumnsFound": "No columns found",
      "noData": "No data",
      "noMetricsFound": "No metrics found",
      "noMetricsSelected": "No metrics selected"
    },
    "values": {
      "all": "All",
      "no": "No",
      "yes": "Yes"
    }
  },
  "entities": {
    "location": {
      "properties": {
        "city": "City",
        "region": "Region"
      }
    },
    "sku": {
      "properties": {
        "brand": "Brand",
        "category": "Category",
        "color": "Color",
        "productId": "Product ID",
        "productName": "Product",
        "season": "Season",
        "size": "Size",
        "style": "Style"
      }
    }
  },
  "globalNavigation": {
    "allocations": "Allocations",
    "analytics": "Analytics",
    "inventory": "Inventory",
    "planningAssistant": "Planning assistant",
    "replenishment": "Replenishment",
    "replenishmentStore": "Store",
    "replenishmentWarehouse": "WH",
    "specialEvents": "Special Events",
    "storeTransfer": "Store Transfer"
  },
  "insufficientReplenishment": {
    "datasetsNames": {
      "replenishment": "Replenishment",
      "salesRate": "Sales rate",
      "stock": "Stock"
    },
    "properties": {
      "country": "Country",
      "creationDate": "Creation date",
      "department": "Department",
      "price": "Price",
      "region": "Region",
      "replenishment": "Replenishment",
      "replenishmentTime": "Replenishment time",
      "supply": "Supply",
      "warehouse": "WH",
      "warehouseId": "WH ID"
    },
    "title": "Insufficient replenishment"
  },
  "inventory": {
    "common": {
      "actions": {
        "avoidReplenishment": "Avoid replenishment",
        "unavoidReplenishment": "Unavoid replenishment"
      },
      "messages": {
        "avoidReplenishmentConfirmation": "Replenishment for the selected items will be stopped. Are you sure you want to avoid it?",
        "avoidReplenishmentFilterWarning": "Avoid replenishment settings show up immediately but might need a few minutes to be ready for filtering",
        "inventoryItemLastUpdateWarning": "These values may not be accurate as they were last updated on {date}",
        "tryAgainLater": "Try again later"
      },
      "properties": {
        "department": "Department",
        "departmentId": "Department ID",
        "description": "Description",
        "location": "Location",
        "locationId": "Location ID",
        "sku": "SKU",
        "skuId": "SKU ID"
      },
      "statuses": {
        "avoidReplenshimentFailed": "Failed to Avoid Replenshiment."
      }
    },
    "locations": {
      "kpi": {
        "inventoryCost": "Inventory cost",
        "plants": "Plants",
        "stores": "Stores",
        "suppliers": "Suppliers",
        "totalInventory": "Inventory",
        "totalLocations": "Locations",
        "vwarehouses": "Virtual warehouses",
        "warehouses": "Warehouses"
      },
      "properties": {
        "classification": "Classification",
        "creationDate": "Creation date",
        "inventoryAtSite": "At site",
        "inventoryCost": "Inventory cost",
        "inventoryInTransit": "In transit",
        "inventoryPrice": "Inventory price",
        "inventoryReserved": "Reserved",
        "locationType": "Location type",
        "numberOfProducts": "# products",
        "numberOfSkus": "# SKUs",
        "replenishmentAvoided": "Avoid replenishment"
      },
      "statuses": {
        "loadKpisFailed": "Failed to load KPIs.",
        "noLocationsFound": "No locations found"
      },
      "supersetDashboardModal": {
        "title": "Analyze location data"
      },
      "title": "Location"
    },
    "skuChart": {
      "metrics": {
        "coverage": "Coverage",
        "inventoryAtSite": "Inventory at site",
        "inventoryAtWarehouse": "At WH",
        "inventoryInTransit": "In transit",
        "inventoryReserved": "Reserved",
        "optimalStock": "Optimal stock",
        "replenishmentRecommendation": "Replenishment recommendation",
        "salesRate": "Sales rate",
        "surplus": "Surplus",
        "transactionsIn": "In",
        "transactionsOut": "Out",
        "transactionsSale": "Sale"
      },
      "metricsGroups": {
        "calculations": "Calculations",
        "inventory": "Inventory level",
        "transactions": "Transactions"
      },
      "properties": {
        "catalogPrice": "Catalog price",
        "cost": "Cost",
        "creationDate": "Creation date",
        "department": "Department",
        "departmentId": "Department ID",
        "location": "Location",
        "locationBrand": "Location brand",
        "locationClassification": "Location classification",
        "locationId": "Location ID",
        "locationType": "Location type",
        "replenishmentTime": "Replenishment time",
        "sku": "SKU",
        "skuId": "SKU ID",
        "source": "Source",
        "targetMarket": "Target market",
        "updateDate": "Update date"
      },
      "title": "SKU history chart"
    },
    "skus": {
      "kpi": {
        "dailySales": "Daily sales",
        "totalCategories": "Categories",
        "totalInventory": "Inventory",
        "totalProducts": "Products",
        "totalSkus": "SKUs"
      },
      "properties": {
        "cost": "Cost",
        "coverage": "Coverage",
        "creationDate": "Creation date",
        "department": "Department",
        "departmentId": "Department ID",
        "market": "Market",
        "numberOfStores": "# stores",
        "packConstraint": "Pack constraint",
        "price": "Price",
        "quantityAtSite": "At site",
        "quantityAtWarehouse": "At WH",
        "quantityInTransit": "In transit",
        "replenishmentAvoided": "Avoid replenishment",
        "reservedQuantity": "Reserved",
        "salesRate": "Sales rate"
      },
      "statuses": {
        "noSkusFound": "No SKUs found"
      },
      "supersetDashboardModal": {
        "title": "Analyze SKU Catalog Data"
      },
      "title": "SKU"
    },
    "skusLocations": {
      "kpi": {
        "totalInventory": "Inventory",
        "totalLocations": "Locations",
        "totalSkus": "SKUs"
      },
      "properties": {
        "classification": "Classification",
        "cost": "Cost",
        "coverage": "Coverage",
        "creationDate": "Creation date",
        "departmentName": "Department name",
        "lastUpdateDate": "Last update",
        "location": "Location",
        "locationDescription": "Location description",
        "locationType": "Location type",
        "market": "Market",
        "maxStock": "Max stock",
        "minStock": "Min stock",
        "optimalStock": "Optimal stock",
        "price": "Price",
        "quantityAtSite": "At site",
        "quantityAtWarehouse": "At WH",
        "quantityInTransit": "In transit",
        "replenishmentAvoided": "Avoid replenishment",
        "replenishmentAvoidedForLocation": "Avoid replenishment (location)",
        "replenishmentAvoidedForSku": "Avoid replenishment (SKU)",
        "reservedQuantity": "Reserved",
        "salesRate": "Sales rate",
        "sku": "SKU",
        "skuDescription": "SKU description",
        "source": "Source",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noInventoriesFound": "No inventories found"
      },
      "supersetDashboardModal": {
        "title": "Analyze SKU-Location Data"
      },
      "title": "SKU-Location"
    },
    "title": "Inventory",
    "transactions": {
      "title": "Transactions"
    }
  },
  "modules": {
    "allocations": {
      "actions": {
        "addAllocations": "Add allocations",
        "downloadCsvTemplate": "Download CSV Template",
        "downloadCsvWithErrors": "Download CSV file (including errors)",
        "uploadAgain": "Upload again",
        "uploadAllocations": "Upload allocations"
      },
      "constraints": {
        "fullSetRequired": "Full set",
        "maxProductQuantity": "Max product",
        "minProductQuantity": "Min product",
        "minSizesNumber": "Min sizes"
      },
      "description": {
        "recommendationDate": "The allocation quantities will be specified on the recommendation date"
      },
      "entities": {
        "inventory": {
          "properties": {
            "atWh": "At WH",
            "atWhAfterAllocation": "WH after allocation",
            "gap": "Gap",
            "locationId": "Location Id",
            "optimalQuantity": "Optimal",
            "productId": "Product ID",
            "quantity": "Units",
            "quantityAtWarehouse": "At WH",
            "recommendationDate": "Recommendation date",
            "size": "Size",
            "sku": "SKU",
            "skuId": "SKU Id",
            "sourceLocationId": "Source Location Id"
          }
        },
        "productLocation": {
          "properties": {
            "allocationDate": "Allocation date",
            "constraints": "Constraints",
            "location": "Location",
            "product": "Product",
            "quantityAtWarehouse": "At WH",
            "recommendationDate": "Recommendation date"
          }
        }
      },
      "howToTableColumnHeaders": {
        "description": "Description",
        "format": "Format/example",
        "name": "Column name",
        "type": "Type"
      },
      "messages": {
        "allAllocationsHaveBeenApproved": "All allocations have been approved",
        "allocation": "Allocation",
        "allocationInList": "Location: {location} {'|'} Product: {product}",
        "allocationsApprovingAllConfirmationText": "Are you sure you want to approve all allocation?",
        "allocationsApprovingAllConfirmationTitle": "Approve all allocations?",
        "allocationsApprovingAllFilterWarning": "The table is currently filtered, clicking \"approve\" will approve all recommendations, not just the filtered rows. Are you sure you want to approve all?",
        "allocationsApprovingConfirmationText": "Are you sure you want to approve this @:modules.allocations.plurals.allocations{''}? | Are you sure you want to approve these @:modules.allocations.plurals.allocations{''}?",
        "allocationsApprovingConfirmationTitle": "Approve {n} @:modules.allocations.plurals.allocations{''}?",
        "allocationsApprovingConstraintsWarning": "Please note that some of the SKUs might have constraints.",
        "allocationsHaveBeenApproved": "{n} @:modules.allocations.plurals.allocations has been approved | {n} @:modules.allocations.plurals.allocations have been approved",
        "approvedAllocationDeletingWarning": "If the allocation process has started, this action may not have any impact.",
        "approvingAllocations": "Approving allocations",
        "calculatingPendingAllocations": "Calculating pending allocations",
        "calculatingPendingAllocationsHint": "We will inform once the calculation is complete",
        "calculatingYourAllocations": "Calculating your new allocations",
        "cancelUpload": "Cancel upload",
        "createAllocation": "Create Allocation",
        "editLimit": "Editing limited to single item",
        "failedToApproveAllocations": "Failed to approve allocations",
        "howToUse": "How to use",
        "howToUseCsvTemplate": "How to use CSV template",
        "itemsHaveBeenRemovedFromAllocationsList": "{n} @:common.plurals.items has been removed from your allocation list | {n} @:common.plurals.items have been removed from your allocation list",
        "leavingPageCancellingConfirmationText": "By leaving this page you will stop uploading your data. Are you sure you want to cancel upload?",
        "noPendingAllocationsAvailable": "There are no pending allocations available",
        "noUpcomingAllocationsAvailable": "There are no upcoming allocations available",
        "quantityExceedsAvailableInventory": "The new value exceeds the available WH inventory",
        "requiredFieldHint": "Mandatory",
        "startAllocationText": "Get started with allocation by adding them through a CSV input file downloaded to your computer and uploaded to Onebeat",
        "startAllocationTitle": "Start allocation",
        "upload": "Upload",
        "uploadAt": "Upload at",
        "uploadCancelled": "Upload canceled",
        "uploadCancellingConfirmationText": "Canceling the upload now will stop the process and your selected file won't be uploaded.",
        "uploadCancellingConfirmationTitle": "Are you sure you want to cancel upload?",
        "uploadComplete": "Upload complete - verifying you data",
        "uploadCompleteInformation": "We will inform once the calculation is complete",
        "uploadCsvHint": "CSV file only (up to 1M rows)",
        "uploadFailed": "Upload failed",
        "uploadStartHint": "Begin your allocation process by filling out the necessary information and inventory in the CSV template file.",
        "uploadStartHintInventoryStatus": "Ensure the inventory status file is reported before the allocation, if the upload is not completed, the warehouse quantity and in-transit units to the warehouse will not be factored into the calculations.",
        "uploadStartHintProducts": "Ensure that all products are pre-defined in the SKU catalog before initiating the allocation upload.",
        "uploadingError": "We found some errors while uploading your data",
        "verifyingYourData": "Verifying your data"
      },
      "navigation": {
        "approvedAllocations": "Approved allocations",
        "pendingAllocations": "Pending approval",
        "scheduledAllocations": "Scheduled allocations"
      },
      "plurals": {
        "allocations": "allocation | allocations"
      },
      "title": "Allocations",
      "uploadSteps": {
        "calculate": "Calculate",
        "ready": "Allocation ready",
        "upload": "Upload input file",
        "verify": "Verify data"
      },
      "uploadTemplateInventoryColumnsDescription": {
        "allocationDate": "Schedule the allocation to the selected date. The default value will be the next shipment.",
        "fullSetConstraint": "This product can be allocated only as a full set (all sizes) or there is a box constraint. Choose one of the following: 'true' or 'false'. The default will be no need for a full set (false). Full set constraint considers the size grid.",
        "locationId": "Location identifier",
        "maxProduct": "Maximum quantity needed for this product-location. The default will be NA.",
        "minProduct": "Minimum quantity needed for this product-location. The default will be NA.",
        "productId": "Product identifier",
        "sourceLocationId": "The origin ID for each product-location"
      }
    },
    "replenishment": {
      "entities": {
        "location": {
          "properties": {
            "store": "Store",
            "warehouse": "WH"
          }
        }
      },
      "messages": {
        "noRecommendationsMatchYourSearch": "No recommendations match your search"
      },
      "storePageTitle": "Store replenishment",
      "subNavigation": {
        "inventories": "SKU-Location",
        "overview": "Overview",
        "product": "Product",
        "store": "Store",
        "warehouse": "Warehouse"
      },
      "timeFrameOptions": {
        "today": "Today",
        "upcoming": "Upcoming"
      },
      "warehousePageTitle": "WH replenishment"
    }
  },
  "replenishment": {
    "common": {
      "kpi": {
        "products": "Products",
        "skus": "SKUs",
        "stores": "Stores",
        "units": "Units",
        "warehouses": "WHs"
      },
      "locationTypes": {
        "store": "Store",
        "warehouse": "WH"
      },
      "statuses": {
        "loadKpisFailed": "Failed to load KPIs.",
        "pickingListLoading": "Replenishment picking list is loading",
        "pickingListLoadingComplete": "Replenishment picking list loading complete",
        "pickingListLoadingFailed": "Replenishment picking list loading failed"
      }
    },
    "constraints": {
      "names": {
        "depleted": "Depleted WH",
        "insufficient": "Insufficient supply",
        "noConstraints": "No constraints",
        "package": "Package constraint",
        "shipmentSchedule": "Schedule constraint"
      }
    },
    "inventories": {
      "description": {
        "constraints": "Constraints that affected the replenishment recommendation",
        "coverage": "The number of days the current inventory of the SKU at the store can sustain (assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate)",
        "inventoryNeeded": "The gap between the optimal inventory level for the SKU at the store and its current stock level",
        "optimalStockVariance": "The gap between current optimal stock to the last day`s optimal stock of that specific SKU-Location",
        "optimalStockVariancePercentage": "The gap in % between current optimal stock to the last day's optimal stock of that specific SKU-Location",
        "replenishmentGap": "The gap between the quantity required to reach the optimal inventory level for the SKU at the store and its current replenishment recommendation",
        "saleRate": "A calculated metric reflecting the true sales power of an SKU-Location, considering availability in stores, and sales fluctuations",
        "stock": "The current inventory level at the store, including inventory that is already on route; hover on a store SKU`s stock to view the inventory breakdown",
        "whInventory": "Inventory at site at the source origin"
      },
      "properties": {
        "assortmentGroup": "AG",
        "constraints": "Constraints",
        "coverage": "Coverage",
        "daysSinceAllocation": "Days since allocation",
        "inventoryNeeded": "Inventory needed",
        "optimalStock": "Optimal stock",
        "optimalStockVariance": "Optimal stock variance",
        "optimalStockVariancePercentage": "Optimal stock variance (%)",
        "replenishment": "Replenishment",
        "replenishmentGap": "Replenishment Gap",
        "replenishmentTime": "Replenishment time",
        "salesRate": "Sales rate",
        "shipmentDays": "Shipment days",
        "skuDescription": "SKU description",
        "sold": "Sold",
        "specialEvent": "Special Event",
        "stock": "Stock",
        "targetMarket": "Target market",
        "unitPrice": "Unit price",
        "warehouseInventory": "WH inventory"
      },
      "title": "SKU-Location"
    },
    "messages": {
      "allReplenishment": "All replenishment",
      "groupAvoidReplenishmentSuccess": "Avoided replenishment for {n} @:common.plurals.items. The changes will take place after the next daily upload.",
      "groupUnavoidReplenishmentSuccess": "Unavoided replenishment for {n} @:common.plurals.items",
      "inventoryAtSite": "Inventory at site",
      "inventoryInTransit": "Inventory in transit",
      "soldInTheLastNDays": "Sold in the last {n} @:common.plurals.days",
      "todaysReplenishment": "Today's replenishment",
      "upcomingReplenishment": "Upcoming replenishment"
    },
    "overview": {
      "actions": {
        "downloadReplenishmentOverview": "Download replenishment overview (CSV)"
      },
      "properties": {
        "averageDailySales": "AVG daily sales",
        "averageDailySalesDepleted": "AVG daily sales n/a at WH",
        "coverage": "Coverage",
        "coverageDepleted": "Coverage n/a at WH",
        "expectedCoverage": "Expected coverage",
        "replenishment": "Replenishment",
        "sold": "Sold",
        "soldDepleted": "Sold n/a at WH"
      },
      "title": "Overview"
    },
    "products": {
      "description": {
        "constraints": "Shipment constraints that affected the replenishment recommendation.",
        "coverage": "The number of days the current inventory of the SKU at the store can sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate",
        "expectedCoverage": "The number of days the replenished inventory of the SKU at the store is expected to sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate",
        "optimalStock": "The optimal inventory level for the SKU at the store",
        "replenishment": "The number of units recommended for the SKU replenishment to the store.",
        "replenishmentTime": "The time it takes for replenished items to reach the store`s shelves.",
        "salesRate": "The average number of units sold per day, on days the SKU was available for shoppers at the store.",
        "sold": "The number of units the SKU sold since the last time it received replenishment at the store.",
        "stock": "The current inventory level at the store, including inventory that is already on route. Hover on a store SKU`s stock to view the inventory breakdown.",
        "warehouseInventory": "The number of units currently available at the supplying warehouse (prior to today`s replenishment).",
        "warehouseName": "The name of the warehouse sending replenishment for the SKU at the store."
      },
      "properties": {
        "constraints": "Constraints",
        "coverage": "Coverage",
        "expectedCoverage": "Expected coverage",
        "locationId": "Location ID",
        "optimalStock": "Optimal stock",
        "replenishment": "Replenishment",
        "replenishmentTime": "Replenishment time",
        "salesRate": "Sales rate",
        "sku": "SKU",
        "skuId": "SKU ID",
        "sold": "Sold",
        "stock": "Stock",
        "targetMarket": "Target market",
        "totalReplenishment": "Replenishment",
        "unitCost": "Unit cost",
        "unitPrice": "Unit price",
        "warehouseInventory": "WH inventory",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noProductsFound": "No products found",
        "productNotFound": "Product not found"
      },
      "title": "Product"
    },
    "stores": {
      "description": {
        "averageDailySales": "The average daily expected income generated by the store`s SKUs (that have available supply at the WH). Calculated according to the catalog price",
        "averageDailySalesDepleted": "The average daily expected income generated by the store`s SKUs (that do not have available supply at the WH). Calculated according to the current sales rate and catalog price.",
        "constraints": "Shipment constraints that affected the replenishment recommendation.",
        "coverage": "The number of days the current inventory of the store`s SKUs (that have available supply at the WH) can sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate and catalog price.",
        "coverageDepleted": "The number of days the current inventory of the store`s SKUs that do not have available supply at the WH can sustain until it runs out of stock, assuming current demand remains relatively steady.",
        "expectedCoverage": "The number of days the replenished inventory of the store`s SKUs is expected to sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate and catalog price.",
        "numberOfProducts": "The number of different products that are expected to receive replenishment.",
        "numberOfSkus": "The number of different SKUs (product-size) that are expected to receive replenishment",
        "replenishment": "The total number of units recommended for replenishment to the store.",
        "replenishmentTime": "The time it takes for replenished items to reach the store`s shelves.",
        "sold": "The number of units sold by SKUs (that have available supply at the WH) since the last time each SKU was replenished at the store.",
        "soldDepleted": "The number of units sold by SKUs (that need replenishment but do not have available supply at the WH) since the last time each SKU was replenished at the store.",
        "warehouse": "The name of the warehouse sending replenishment to the store"
      },
      "messages": {
        "searchByProductHint": "Search by product"
      },
      "properties": {
        "averageDailySales": "AVG daily sales",
        "averageDailySalesDepleted": "AVG daily sales n/a at WH",
        "classification": "Classification",
        "constraints": "Constraints",
        "coverage": "Coverage",
        "coverageDepleted": "Coverage n/a at WH",
        "expectedCoverage": "Expected coverage",
        "locationId": "Location ID",
        "locationType": "Location type",
        "numberOfProducts": "# products",
        "numberOfSkus": "# SKUs",
        "replenishment": "Replenishment",
        "replenishmentTime": "Replenishment time",
        "sold": "Sold",
        "soldDepleted": "Sold n/a at WH",
        "storeId": "Store ID",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noRecommendationScheduledFutureText": "Check the Today View for recommendations that are shipping today.",
        "noRecommendationScheduledFutureTitle": "There are no scheduled recommendations for future shipments.",
        "noRecommendationScheduledTodayText": "Please check the Upcoming View for future replenishment recommendations.",
        "noRecommendationScheduledTodayTitle": "No recommendations are scheduled to ship today.",
        "storeNotFound": "Store not found"
      },
      "storeSummaryProperties": {
        "numberOfProducts": "# products",
        "numberOfSkus": "# SKUs",
        "sold": "Sold",
        "totalReplenishment": "Replenishment"
      },
      "title": "Location"
    }
  },
  "specialEvents": {
    "actions": {
      "approve": "Approve",
      "approveEvent": "Approve event",
      "downloadCsvTemplate": "Download CSV Template",
      "downloadCsvWithErrors": "Download CSV file (including errors)",
      "uploadAgain": "Upload again",
      "uploadEvents": "Upload events",
      "viewEventDetails": "View event details"
    },
    "eventProperties": {
      "avgFactor": "Avg factor",
      "categoriesCount": "Categories",
      "description": "Description",
      "discountType": "Discount type",
      "endDate": "End date",
      "event": "Event",
      "eventId": "Event ID",
      "eventType": "Event type",
      "factor": "Factor",
      "maxFactor": "Max factor",
      "minFactor": "Min factor",
      "optimalStock": "Optimal stock",
      "percentageChange": "Percentage change",
      "preparationDate": "Preparation date",
      "productsCount": "Products",
      "quantityAtSite": "At site",
      "recommendationDate": "Recommendation date",
      "recurrentEventName": "Recurrent event name",
      "segmentation": "Segmentation",
      "skusCount": "SKU",
      "startDate": "Start date",
      "storesCount": "Stores"
    },
    "eventPropertiesDescription": {
      "avgFactor": "The average expected sales change for all the inventory in the even",
      "maxFactor": "The maximal factor available in the event`s sku-location",
      "minFactor": "The minimal factor available in the event`s sku-location",
      "optimalStock": "The daily quantity target needed for the event",
      "preparationDate": "The date on which the shipment start being sent to the designated locations",
      "quantityAtSite": "The 'at site' quantities for all the inventory in the event",
      "recommendationDate": "The date when the optimal stock recommendation for the event will be available. Will be able to approve it under 'pending'."
    },
    "howToTableColumnHeaders": {
      "description": "Description",
      "format": "Format/example",
      "name": "Column name",
      "type": "Type"
    },
    "inventories": {
      "description": {
        "atSite": "Current quantity for the SKU in this location",
        "factor": "The expected sales change for the inventory during the event",
        "nextShipment": "The date of the next shipment for this sku-location",
        "optimalStock": "The daily quantity target needed for this inventory"
      }
    },
    "inventoryProperties": {
      "factor": "Factor",
      "nextShipment": "Next shipment",
      "percentageChange": "Percentage change",
      "preparationDate": "Preparation date",
      "segmentation": "Segmentation"
    },
    "messages": {
      "approvedEvents": "Approved events",
      "deleteInventories": "Delete inventories",
      "eventDetails": "Event details",
      "eventInventoriesDeleteConfirmation": "Are you sure you want to delete {n} @:common.plurals.items{''}?`",
      "eventsList": "Events list",
      "feelFreeToLeavePage": "Feel free to leave this page, and we'll notify you once the processing is complete.",
      "getStartedText": "Get started with special events by adding them through a CSV input file downloaded to your computer and uploaded to Onebeat.",
      "getStartedTitle": "Start Special Events",
      "howToUse": "How to use",
      "howToUseCsvTemplate": "How to use CSV template",
      "inventoriesDeletingInProgress": "Deleting inventories...",
      "inventoriesDeletingSuccess": "{n} @:common.plurals.items have been deleted.The changes will take place after the next daily upload.",
      "inventoryDetails": "SKU-Location details",
      "noEventsFound": "No events found",
      "ongoingEventInventoriesDeleteConfirmation": "The selected item is part of an active event. Deleting it now will remove it from the schedule. @:specialEvents.messages.eventInventoriesDeleteConfirmation | The selected items are part of an active event. Deleting them now will remove them from the schedule. @:specialEvents.messages.eventInventoriesDeleteConfirmation",
      "pendingEvents": "Pending approval",
      "processingYourData": "Processing your data",
      "requiredFieldHint": "Mandatory",
      "sameEventDetailsHint": "Must be the same for all SKU-Locations within the event.",
      "scheduledEvents": "Scheduled events",
      "specialEvent": "Special Event",
      "specialEvents": "Special Events",
      "uploadAt": "Upload at",
      "uploadCancelled": "Upload canceled",
      "uploadCancellingConfirmationText": "Canceling the upload now will stop the process and your selected file won't be uploaded. Are you sure you want to cancel it?",
      "uploadCancellingConfirmationTitle": "Cancel upload?",
      "uploadComplete": "Upload complete - verifying you data",
      "uploadFailed": "Upload failed",
      "uploadSpecialEvents": "Upload Special Events",
      "uploadStartHint": "Start creating special events by filling out the necessary information and inventory in the CSV template file.",
      "uploadZipHint": "For CSV files over 50MB, compress and upload as a ZIP",
      "uploadedEventsAreUnderCalculation": "{n} @:common.plurals.events has been successfully uploaded and is currently undergoing calculation. It will be visible on the screen shortly. | {n} @:common.plurals.events have been successfully uploaded and are currently undergoing calculation. They will be visible on the screen shortly.",
      "uploadingError": "We found some errors while uploading your data",
      "verifyingYourData": "Verifying your data"
    },
    "uploadSteps": {
      "process": "Process data",
      "ready": "Special Events ready",
      "upload": "Upload input file",
      "verify": "Verify data"
    },
    "uploadTemplateEventColumnsDescription": {
      "description": "Detailed information about the event.",
      "endDate": "The date on which the event is scheduled to finish.",
      "eventId": "Unique event identifier provided by the user.",
      "eventName": "Name of the event provided by the user.",
      "eventType": "Choose one of the following: 'promotion' or 'holiday'. If empty, it will be a 'promotion'.",
      "oneYearAgoEndDate": "When the same event ended 1 year ago. Learn from this event history. If it is only one day, the start and end date can be the same.",
      "oneYearAgoStartDate": "When the same event started 1 year ago. Learn from this event history.",
      "recurrentEventName": "The recurring event name. The name should be identical to the previous event to be able to compare the coming event to past similar events.",
      "startDate": "The date on which the event is scheduled to begin.",
      "twoYearsAgoEndDate": "When the same event ended 2 years ago. Learn from this event history. If it is only one day, the start and end date can be the same.",
      "twoYearsAgoStartDate": "When the same event started 2 years ago. Learn from this event history."
    },
    "uploadTemplateInventoryColumnsDescription": {
      "discountType": "Choose one of the following: 'discount' or 'points'.",
      "factor": "What is the expected sales change for this inventory-location in the chosen event (can be as an input from client or calculated by Onebeat).",
      "locationId": "Location participating. Connect between location to inventory participating.",
      "percentageChange": "What is the change according to the discount type in percentages from the current price. Can also be a negative number.",
      "preparationDate": "The date on which the shipment is sent to the designated locations to start preparing the inventory for the event.",
      "segmentation": "On which segment the event applies: 'club' or 'all'",
      "skuId": "SKU ID participating in the selected event."
    }
  },
  "storeTransfer": {
    "common": {
      "messages": {
        "clustersDownloadingInProgress": "Picking list is loading",
        "pickingListDownloadingComplete": "Picking list loading complete",
        "pickingListDownloadingFailed": "Picking list loading complete"
      },
      "properties": {
        "department": "Department",
        "esi": "Estimated Sales Increase",
        "esiShort": "ESI",
        "targetMarket": "Target market",
        "totalProducts": "Products",
        "totalStores": "Stores",
        "totalUnits": "Units"
      },
      "statuses": {
        "clusterNotFound": "Cluster not found",
        "noProductsFound": "No products found"
      }
    },
    "navigation": {
      "recommendations": "Pending approval"
    },
    "plurals": {
      "cluster": "cluster | clusters"
    },
    "recommendations": {
      "actions": {
        "approve": "Approve",
        "approveAll": "Approve all",
        "recover": "Recover",
        "reject": "Reject",
        "seeTripDetails": "See trip details",
        "undoApproval": "Undo approval"
      },
      "allClusters": "All clusters",
      "approvedClusters": "Approved clusters",
      "clusterProperties": {
        "esi": "ESI",
        "stores": "Stores",
        "trips": "Trips",
        "units": "Units"
      },
      "messages": {
        "allClustersApproved": "All clusters have been approved",
        "approvedClustersRecoveryText": "Recover approved cluster recommendations.",
        "balancing": "Balancing",
        "calculationInProgressText": "{completed} of {total} clusters are ready",
        "calculationInProgressTitle": "Calculation is in progress",
        "calculationInactiveText": "To start using Store Transfer you need to set up store clusters.",
        "calculationInactiveTitle": "Start moving around",
        "clusterApprovalFailed": "Failed to approve clusters",
        "clusterApprovalSuccess": "{clusterName} cluster has been approved",
        "clusterApprovalUndoFailed": "Failed to undo clusters approval",
        "clusterApprovalUndoInProgress": "Undoing clusters approval",
        "clusterRejectionFailed": "Cluster rejection failed",
        "clusterRejectionSuccess": "{clusterName} cluster has been rejected",
        "clusterRejectionUndoFailed": "Failed to undo clusters rejection",
        "clusterRejectionUndoInProgress": "Undoing clusters rejection",
        "clustersApprovalSuccess": "{n} @:storeTransfer.plurals.cluster have been approved",
        "clustersApprovalSuccessNotes": "Please make sure to complete the transfers in the next {n}\u00a0@:common.plurals.days{''}.",
        "clustersDownloadingComplete": "Clusters list loading complete",
        "clustersDownloadingFailed": "Clusters list loading complete",
        "clustersDownloadingInProgress": "Clusters list is loading",
        "clustersRecoveryFailed": "Failed to restore clusters",
        "clustersRecoveryTitle": "Cluster recovery",
        "clustersRejectionInProgress": "Rejecting @:storeTransfer.plurals.cluster",
        "clustersRejectionSuccess": "{n} @:storeTransfer.plurals.cluster have been rejected",
        "consolidation": "Consolidation",
        "nextCalculationOnDate": "Next calculation is on {date}",
        "noMorePendingRecommendations": "No more pending recommendations",
        "noRecommendationsAvailableTodayText": "Based on our calculations, no trips passed the the minimum thresholds set by you.",
        "noRecommendationsAvailableTodayTitle": "No recommendations are available today.",
        "noStoreTransferRequiredText": "If you would like to recalculate, please modify settings by contacting support.",
        "noStoreTransferRequiredTitle": "Current inventory allocation is optimal, no store transfers required.",
        "rejectClusterConfirmationTitle": "Are you sure you want to reject {clusterName} cluster?",
        "rejectClustersConfirmationTitle": "Are you sure you want to reject these clusters?",
        "rejectConfirmationText": "Rejecting a cluster cancels all the trips within the cluster. If you would like to recalculate the cluster, please contact support to modify settings.",
        "rejectedClustersRecoveryText": "Recover rejected cluster recommendations.",
        "totalClusters": "Total of {n}\u00a0@:storeTransfer.plurals.cluster"
      },
      "navigation": {
        "products": "Products",
        "trips": "Trips"
      },
      "products": {
        "title": "Store Transfer - Products"
      },
      "rejectedClusters": "Rejected clusters",
      "skuProperties": {
        "kept": "Do not move",
        "received": "Received",
        "sent": "Send"
      },
      "summary": {
        "esi": "Estimated Sales Increase",
        "pendingClusters": "New clusters pending review",
        "pendingTrips": "New trips pending review",
        "products": "Products",
        "stores": "Stores",
        "trips": "Trips",
        "units": "Units"
      },
      "tripProperties": {
        "esi": "Estimated Sales Increase",
        "from": "From",
        "products": "Products",
        "to": "To",
        "units": "Units"
      },
      "trips": {
        "title": "Store Transfer - Trips"
      }
    },
    "title": "Store Transfer"
  },
  "ui": {
    "BatchActionsMenu": {
      "labelSelectedItems": "@:common.plurals.items selected"
    },
    "TablePagination": {
      "labelDisplayedRows": "{min}-{max} of {total} items",
      "labelItemsPerPage": "Items",
      "labelRowsPerPage": "Rows"
    },
    "datePickerShortcuts": {
      "lastDay": "Last day",
      "lastDays": "Last {n}\u00a0@:common.plurals.days{''}",
      "lastMonths": "Last {n}\u00a0@:common.plurals.months{''}",
      "lastYear": "Last year"
    },
    "fileUploader": {
      "labelTrigger": "Click to upload or drag and drop"
    },
    "weekdays": {
      "friday": "Friday",
      "monday": "Monday",
      "saturday": "Saturday",
      "sunday": "Sunday",
      "thursday": "Thursday",
      "tuesday": "Tuesday",
      "wednesday": "Wednesday"
    }
  },
  "userProfile": {
    "actions": {
      "changePassword": "Change password",
      "editProfile": "Edit profile",
      "signOut": "Sign out"
    },
    "labels": {
      "language": "Language"
    }
  },
  "validationErrors": {
    "plurals": {
      "symbol": "symbol | symbols"
    },
    "string": {
      "min": "Min length is {n} @:validationErrors.plurals.symbol"
    }
  }
}
