import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';

const DATABASE_KEY = 'sku-chart';

export const useSkuChartStore = defineStore(StoreNames.SkuChart, () => {
  const visibleMetrics = ref(['at_site', 'in_transit', 'optimal_stock', 'sale']);

  const api = useUiStatesApi();

  const fetching = ref(false);
  const fetched = ref(false);

  async function fetch(force?: boolean) {
    if (fetching.value || (fetched.value && !force)) {
      return;
    }

    fetching.value = true;

    // Fetch persisted state from the server
    const { data } = await api.getUiStates({ key: DATABASE_KEY });

    const persistedState = data.data[0];

    if (persistedState) {
      // TODO: validate and merge data, persist again in case of invalid values
      visibleMetrics.value = persistedState.value.visibleMetrics;
    }

    fetching.value = false;
    fetched.value = true;
  }

  async function persist() {
    await api.saveUiState({
      key: DATABASE_KEY,
      value: {
        visibleMetrics: visibleMetrics.value,
      },
    });
  }

  const debouncedPersist = useDebounceFn(persist, 3000);
  const autoPersistEnabled = ref(false);

  watch(
    [visibleMetrics],
    () => {
      if (autoPersistEnabled.value) {
        debouncedPersist();
      }
    },
    {
      deep: true,
    },
  );

  watch(fetched, () => {
    autoPersistEnabled.value = true;
  });

  // start migration from local storage
  // TODO: remove in September 2024
  const localStorageKey = 'onebeat-app:sku-chart';
  const localStorageState = window.localStorage.getItem(localStorageKey);

  if (localStorageState) {
    try {
      const values = JSON.parse(localStorageState);

      visibleMetrics.value = values.visibleMetrics;
      persist();
      fetched.value = true;
    } catch (error) {
      // do nothing
    } finally {
      window.localStorage.removeItem(localStorageKey);
    }
  }
  // end migration from local storage

  return {
    fetching,
    fetched,
    fetch,
    visibleMetrics,
  };
});
