import { defineStore } from 'pinia';
import { z } from 'zod';
import { ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { pick } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';
import type { ReplenishmentLocationsFilter } from '../interfaces';

enum Columns {
  Name = 'name',
  ExternalId = 'external_id',
  LocationType = 'location_type',
  City = 'city',
  Sold = 'sold',
  Coverage = 'coverage',
  Replenishment = 'replenishment',
  ExpectedCoverage = 'expected_coverage',
  AvgDailySales = 'avg_daily_sales',
  SourceLocations = 'source_locations',
  Region = 'region',
  TotalProducts = 'total_products',
  TotalSkus = 'total_skus',
  ReplenishmentTime = 'replenishment_time',
  Classifications = 'classifications',
  Brands = 'brands',
  SoldDepleted = 'sold_depleted',
  CoverageDepleted = 'coverage_depleted',
  AvgDailySalesDepleted = 'avg_daily_sales_depleted',
  Constraints = 'constraints',
}

const DATABASE_KEY = 'replenishment-locations-page';

export const useReplenishmentLocationsPageStore = defineStore(
  StoreNames.ReplenishmentLocationsPage,
  () => {
    const columnsVisibility = ref<Record<string, boolean>>({
      [Columns.Name]: true,
      [Columns.ExternalId]: false,
      [Columns.LocationType]: false,
      [Columns.City]: false,
      [Columns.Sold]: true,
      [Columns.Coverage]: false,
      [Columns.Replenishment]: true,
      [Columns.ExpectedCoverage]: false,
      [Columns.AvgDailySales]: false,
      [Columns.SourceLocations]: false,
      [Columns.Region]: false,
      [Columns.TotalProducts]: true,
      [Columns.TotalSkus]: true,
      [Columns.ReplenishmentTime]: false,
      [Columns.Classifications]: false,
      [Columns.Brands]: false,
      [Columns.SoldDepleted]: false,
      [Columns.CoverageDepleted]: false,
      [Columns.AvgDailySalesDepleted]: false,
      [Columns.Constraints]: true,
    });

    const columnsOrder = ref<string[]>([
      Columns.Name,
      Columns.ExternalId,
      Columns.LocationType,
      Columns.City,
      Columns.Sold,
      Columns.Coverage,
      Columns.Replenishment,
      Columns.ExpectedCoverage,
      Columns.AvgDailySales,
      Columns.SourceLocations,
      Columns.Region,
      Columns.TotalProducts,
      Columns.TotalSkus,
      Columns.ReplenishmentTime,
      Columns.Classifications,
      Columns.Brands,
      Columns.SoldDepleted,
      Columns.CoverageDepleted,
      Columns.AvgDailySalesDepleted,
      Columns.Constraints,
    ]);

    const sorting = ref<{ sortBy: string | null; sortOrder: 'asc' | 'desc' }>({
      sortBy: null,
      sortOrder: 'asc',
    });

    const appliedFilters = ref<ReplenishmentLocationsFilter>({
      region: [],
      city: [],
    });

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    async function fetch(force?: boolean) {
      if (fetching.value || (fetched.value && !force)) {
        return;
      }

      fetching.value = true;

      // Fetch persisted state from the server

      const { data } = await api.getUiStates({ key: DATABASE_KEY });

      const persistedState = data.data[0];

      if (persistedState) {
        // TODO: validate and merge data, persist again in case of invalid values
        columnsVisibility.value = {
          ...columnsVisibility.value,
          ...pick(persistedState.value.columnsVisibility, Object.keys(columnsVisibility.value)),
        };

        columnsOrder.value = persistedState.value.columnsOrder;
        sorting.value = persistedState.value.sorting;
        appliedFilters.value = persistedState.value.appliedFilters;
      }

      const sortingSchema = z.object({
        sortBy: z.string().nullable().catch(sorting.value.sortBy),
        sortOrder: z.enum(['asc', 'desc']).catch(sorting.value.sortOrder),
      });

      sorting.value = sortingSchema.parse(sorting);

      fetching.value = false;
      fetched.value = true;
    }

    async function persist() {
      await api.saveUiState({
        key: DATABASE_KEY,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          sorting: sorting.value,
          appliedFilters: appliedFilters.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    watch(
      [columnsVisibility, columnsOrder, sorting, appliedFilters],
      () => {
        if (autoPersistEnabled.value) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    watch(fetched, () => {
      autoPersistEnabled.value = true;
    });

    // start migration from local storage
    // TODO: remove in September 2024
    const localStorageKey = 'onebeat-app:replenishment-locations-page';
    const localStorageState = window.localStorage.getItem(localStorageKey);

    if (localStorageState) {
      try {
        const values = JSON.parse(localStorageState);

        columnsVisibility.value = values.columnsVisibility;
        columnsOrder.value = values.columnsOrder;
        sorting.value = values.sorting;
        appliedFilters.value = values.appliedFilters;
        persist();
        fetched.value = true;
      } catch (error) {
        // do nothing
      } finally {
        window.localStorage.removeItem(localStorageKey);
      }
    }
    // end migration from local storage

    return {
      fetching,
      fetched,
      fetch,
      columnsVisibility,
      columnsOrder,
      sorting,
      appliedFilters,
    };
  },
);
