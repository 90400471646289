import { defineStore } from 'pinia';
import { useDebounceFn } from '@vueuse/core';
import { ref, watch } from 'vue';
import { z } from 'zod';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';

const DATABASE_KEY = 'replenishment-overview-page-store';

export const useReplenishmentOverviewPageStore = defineStore(
  StoreNames.ReplenishmentOverviewPage,
  () => {
    const visibleMetrics = ref<string[]>(['replenishment', 'sold']);

    const sorting = ref<{ sortBy: string | null; sortOrder: 'asc' | 'desc' }>({
      sortBy: 'replenishment',
      sortOrder: 'desc',
    });

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    async function fetch(force?: boolean) {
      if (fetching.value || (fetched.value && !force)) {
        return;
      }

      fetching.value = true;

      // Fetch persisted state from the server

      const { data } = await api.getUiStates({ key: DATABASE_KEY });

      const persistedState = data.data[0];

      if (persistedState) {
        visibleMetrics.value = persistedState.value.visibleMetrics;
        sorting.value = persistedState.value.sorting;
      }

      const sortingSchema = z.object({
        sortBy: z.string().nullable().catch(sorting.value.sortBy),
        sortOrder: z.enum(['asc', 'desc']).catch(sorting.value.sortOrder),
      });
      const visibleMetricsSchema = z.array(z.string()).catch(visibleMetrics.value);

      sorting.value = sortingSchema.parse(sorting);
      visibleMetrics.value = visibleMetricsSchema.parse(visibleMetrics);

      fetching.value = false;
      fetched.value = true;
    }

    async function persist() {
      await api.saveUiState({
        key: DATABASE_KEY,
        value: {
          visibleMetrics: visibleMetrics.value,
          sorting: sorting.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    watch(
      [visibleMetrics, sorting],
      () => {
        if (autoPersistEnabled.value) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    watch(fetched, () => {
      autoPersistEnabled.value = true;
    });

    // start migration from local storage
    // TODO: remove in September 2024
    const localStorageKey = 'onebeat-app:replenishment-overview-page';
    const localStorageState = window.localStorage.getItem(localStorageKey);

    if (localStorageState) {
      try {
        const values = JSON.parse(localStorageState);
        visibleMetrics.value = values.visibleMetrics;
        sorting.value = values.sorting;
        persist();
        fetched.value = true;
      } catch (error) {
        // do nothing
      } finally {
        window.localStorage.removeItem(localStorageKey);
      }
    }
    // end migration from local storage

    return {
      fetching,
      fetched,
      fetch,
      visibleMetrics,
      sorting,
    };
  },
);
